import { gql } from "@apollo/client";

const GET_CART = gql`
    query GET_CART {
      cart {
        isEmpty
        subtotal
        total
        discountTotal
        contents {
          edges {
            node {
              key
              product {
                node {
                  id
                  name
                  shortDescription
                  slug
                  onSale
                  image {
                    uri
                    link
                  }
                  ... on SimpleProduct {
                    id
                    name
                    stockStatus
                    price
                  }
                }
              }
              quantity
              total
              subtotal
            }
          }
          itemCount
        }
      }
    }
`;

export default GET_CART;
