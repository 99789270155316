import React, {useState,useRef,useEffect} from "react"
import HeardTopWrapper from "../components/header-wrapper"
import Layout from "../components/layout/index"
import PageContainer from "../components/page-container"
import CartIcon from "../components/cart/cart-icon-right"
import {Table,Container,Row,Col,Button} from "react-bootstrap"
import Link from "gatsby-link";
//import Billing from "../components/checkout/checkout-billing"
import img from "../images/blog.jpg"
import { Helmet } from 'react-helmet';
import loadable from '@loadable/component'
import CheckoutCartItem from "../components/checkout/checkout-cart-item"
import PaymentMode from "../components/checkout/payment-mode"
import GET_CART from "../queries/get-cart";
import { useQuery, useMutation } from "@apollo/client";
import CHECKOUT_MUTATION from "../mutations/checkout"
import {createCheckoutData,authToLogin} from "../utils/functions"
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {graphql} from "gatsby"

const Billing = loadable(() => import("../components/checkout/checkout-billing"))
const Checkout = () => {
  useEffect(() => {
    authToLogin()
  }, []);
  const {t} = useTranslation();
  const [cartProduct, setCartProduct] = useState([]);
  const [totalPrice, setTotalPrice] = useState(null);
  const [totalQty, setTotalQty] = useState(null);
  const [orderData, setOrderData] = useState(null);

  

  //get cart data
  const { data, refetch } = useQuery(GET_CART, {
    fetchPolicy:"cache-and-network",
    onCompleted: () => {
      let cartData = data.cart.contents.edges
      setCartProduct(cartData)
      setTotalPrice(data.cart.total)
      setTotalQty(data.cart.contents.itemCount)
    },
    onError: (error) => {
      console.log(error)
    },
  });
  let BillingRef = React.createRef();
  const placeOrder = () =>{
    BillingRef.current.submitFun();
    
  }
  const getBillingMsg = (data) =>{
    const checkoutData = createCheckoutData(data)
    setOrderData(checkoutData)
    checkout();
    console.log(data)
  }
  const [
    checkout,
    { data: checkoutResponse, loading: checkoutLoading },
  ] = useMutation(CHECKOUT_MUTATION, {
    variables: {
      input: orderData,
    },
    onCompleted: () => {
      // console.warn( 'completed CHECKOUT_MUTATION' );
      //refetch();
    },
    onError: (error) => {
      if (error) {
        // setRequestError(error.graphQLErrors[0].message);
      }
    },
  });

  return(
    <div>
      <HeardTopWrapper title={t('shoppingCart')}/>
    <Layout>
        <Helmet>
            <title>{t('myKitchenInspector')}｜{t('checkout')}</title>
          </Helmet>
        <PageContainer>
          <div className="topBreadscrum">
            <div className="topBreadscrumLeft"><Link to="/shop" className="top-nav">{t('productList')}</Link> &gt; {t('checkout')}</div>
            <CartIcon totalQty={totalQty}/>
          </div>
          
          <Container fluid className="checkout-container">
            <Row className="checkout-row">
              <Col xs={12} md={6} className="checkout-row-left">
                <Billing ref={BillingRef} getBillingMsg={getBillingMsg}/>
              </Col>
              <Col xs={12} md={6} className="checkout-row-right">
                <CheckoutCartItem item = {cartProduct} />
                <PaymentMode/>
                <div>
                  <div className="">
                    <div className="cart-totals checkout-cart-totals">
                      <h3> {t('cartTotals')}</h3>
                      <div className="totals-warp">
                        <div className="flex">
                          <div>{t('subTotal')}</div>
                          <h5>{totalPrice}</h5>
                        </div>
                        <div className="flex">
                          <div>{t('tax')}</div>
                          <h5>0</h5>
                        </div>
                        <div className="flex">
                          <div><b>{t('total')}</b></div>
                          <h1>{totalPrice}</h1>
                        </div>
                      </div>
                      <div className="text-right">
                       <Button className="addButton" onClick={placeOrder}>{t('placeOrder')}</Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </PageContainer>
      </Layout>

  </div>

  )
}

export default Checkout
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
