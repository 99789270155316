import React from 'react';
import {Table} from "react-bootstrap"
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {graphql} from "gatsby"
const CheckoutCartItem = ( { item } ) => {
	const {t} = useTranslation();
	return (
		<div>
      <h2>{t('yourOrder')}</h2>
			<Table responsive="md" className="table-custom">
			<thead>
				<tr>
					<th>{t('products')}</th>
					<th>{t('total')}</th>
				</tr>
			</thead>
			<tbody>
			{ 
				item.map((item, index) => {
					return <tr key={`item_${index}`}>
					<td>
						<div className="product-info">
							<div>
								<h5>{item.node.product.node.name}  × {item.node.quantity}</h5>
							</div>
						</div>
					</td>
					<td>
					{item.node.total}
					</td>
				</tr>
				})
			}
			</tbody>
	</Table>
</div>
	)
};

export default CheckoutCartItem;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;


