import { gql } from "@apollo/client";

const CHECKOUT_MUTATION = gql`
mutation CHECKOUT_MUTATION( $input: CheckoutInput! ) {
  checkout(input: $input) {
    redirect
    result
    order {
      date
      dateCompleted
      datePaid
      customerNote
      orderNumber
      paymentMethodTitle
      pricesIncludeTax
      total
      totalTax
      shippingTax
      shippingTotal
      shipping {
        address1
        address2
        city
        country
        company
        email
        firstName
        lastName
        phone
        postcode
        state
      }
      billing {
        address1
        address2
        city
        company
        country
        email
        firstName
        lastName
        phone
        postcode
        state
      }
      status
    }
  }
}
`;

export default CHECKOUT_MUTATION;
