import React from 'react';
import {Tabs,Tab} from "react-bootstrap"
import loadable from '@loadable/component'
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {graphql} from "gatsby"

const PaymentForm = loadable(() => import("./payment-mode-form"))

const Index = ( {} ) => {
	const {t} = useTranslation();
	return (
		<div>
      <h2>{t('chooseApaymentMethod')}</h2>
			<Tabs defaultActiveKey="mastercard" className="mb-3">
				<Tab eventKey="mastercard" title="mastercard">
					<div>
						<PaymentForm/>
					</div>
				</Tab>
				<Tab eventKey="paypal" title="paypal">
					2
				</Tab>
				<Tab eventKey="visa" title="visa">
					3
				</Tab>
			</Tabs>
		
		</div>
	);
};

export default Index;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

